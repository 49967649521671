.bccampus-search-bar {
  width: 100%;
}

.bccampus-search-bar input {
  font-size: 1.25rem !important;
}

.bccampus-search-bar>div:first-child {
  flex: 1;
}

.bccampus-search-bar .ant-input-affix-wrapper {
  border-radius: 2em !important;
  padding: 0.5em 0.5em 0.5em 1em;
  align-items: center;
}

.bccampus-search-bar .ant-input-affix-wrapper-focused {
  outline       : var(--button-outline-focus);
  outline-offset: var(--outline-offset);
}

.bccampus-search-bar .ant-input-suffix {
  padding-left: 0.25em;
  line-height : 1em;
  font-size: 1.25rem !important;
}
