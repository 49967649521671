.bccampus-course-info-card {
    padding         : 0em;
    width           : 100%;
    height          : 100%;
    background-color: var(--background-color);
}

.bccampus-course-info-card:hover {
    box-shadow: 0px 0px 8px 2px #3b1047 !important;
}

.bccampus-course-info-card-cover {
    object-fit   : cover;
    object-position: center top;
    width        : 100%;
    height       : 8em;
    border-radius: 8px 8px 0px 0px !important;
}

.bccampus-course-info-card-body {
    height : 5em;
    padding: 1em 1.5em;
}

.bccampus-course-info-card-meta {
    width        : 100%;
    padding      : 1em 1.5em;
    border-radius: 0px 0px 8px 8px !important;
}

.bccampus-course-info-card-tag {
    padding         : 0.5em 1.5em;
    background-color: #3b1047;
    color           : #ffffff;
}
