.bccampus-site-footer {
    padding-top: 4em !important;
    padding-bottom: 4em !important;
}

.bccampus-site-footer .bccampus-logo {
    width: 12em;
}

.bccampus-site-footer-band {
    text-align: center;
    min-height: 2em;
    padding-top: 1em !important;
    padding-bottom: 1em !important;
}
