.bccampus-card-title {
  margin-bottom: 0.25em !important;
  width        : 100%;
  font-size    : 1.15em;
  line-height  : 1.25em;
  font-weight  : 600;
}

.bccampus-card-subtitle {
  margin-bottom: 0.25em !important;
  width        : 100%;
  font-size    : 1em;
  line-height  : 1.25em;
}

.bccampus-card-extra {
  width      : 100%;
  font-size  : 1em;
  line-height: 1.25em;
}

.bccampus-card-meta {
  font-size  : 0.95em;
  line-height: 1.25em;
}


.bccampus-features {
  width: 100%;
}

.bccampus-feature-label {
  color: #28837e;
}

.bccampus-feature-tag {
  border       : none;
  border-radius: 1em;
  padding      : 0.25em 1em;
}

.bccampus-feature-tag.style-1 {
  border-color    : #28837e;
  background-color: #28837e;
  color           : #ffffff;
}

.bccampus-feature-tag.style-2 {
  border-color    : #713b8c;
  background-color: #713b8c;
  color           : #ffffff;
}

.bccampus-feature-label svg,
.bccampus-feature-tag svg {
  margin-right: 0.25em;
}

#about .bccampus-feature-tag {
  border-radius: 4px;
}
