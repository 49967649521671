.bccampus-horizontal {
  display        : flex;
  height         : 100%;
  /* horizontal alignment */
  justify-content: flex-start;
  /* vertical aligement */
  align-items    : center;
}

.bccampus-horizontal.align-center {
  justify-content: center;
}

.bccampus-horizontal.align-left {
  justify-content: start;
}

.bccampus-horizontal.align-right {
  justify-content: end;
}

.bccampus-horizontal.align-top {
  align-items: flex-start;
}

.bccampus-horizontal.align-bottom {
  align-items: flex-end;
}

.bccampus-horizontal>* {
  margin     : 0em;
  line-height: 0px !important;
}

.bccampus-horizontal>*:first-child {
  margin-left: 0rem !important;
}

.bccampus-horizontal>*:last-child {
  margin-right: 0rem !important;
}

.bccampus-horizontal>.stretched {
  flex: 1;
}

.bccampus-horizontal>.stretched.double {
  flex: 2;
}

.bccampus-horizontal>.spacer {
  flex: auto;
}

.bccampus-horizontal>.truncate-text {
  white-space  : nowrap;
  overflow     : hidden;
  text-overflow: ellipsis;
}

.bccampus-horizontal>.quarter {
  width: 25%;
}

.bccampus-horizontal>.half {
  width: 50%;
}

.bccampus-horizontal>.sm {
  width: 4rem;
}

.bccampus-horizontal>.md {
  width: 8rem;
}

.bccampus-horizontal>.lg {
  width: 12rem;
}

.bccampus-horizontal>.xl {
  width: 20rem;
}

.bccampus-horizontal>.centered {
  text-align: center;
}
