.bccampus-site-header .bccampus-logo {
    height: calc(var(--app-bar-height) - var(--padding)*2);
}

.bccampus-site-header .slide-out {
    position: absolute;
    top     : -5em;
    transition: top 0.5s;
}

.bccampus-site-header .slide-in {
    position: absolute;
    top     : 0em;
    transition: top 0.5s;
}