.bccampus-toggle {
  border-radius   : var(--button-border-radius);
  border-style    : solid;
  border-width    : var(--button-border-width);
  border-color    : var(--button-background-color);
  outline-offset  : var(--outline-offset);
  background-color: var(--button-background-color);
  display         : flex;
  white-space     : nowrap;
  gap             : var(--button-border-width);
}

.bccampus-toggle:focus-within {
  outline: var(--button-outline-focus);
}

.bccampus-toggle .bccampus-button {
  border: none;
}

.bccampus-toggle .bccampus-button.secondary {
  background-color: var(--background-color);
}

.bccampus-toggle .bccampus-button:focus {
  outline: none !important;
}

.bccampus-toggle .bccampus-button.first-toggle-item {
  border-top-right-radius   : 0em;
  border-bottom-right-radius: 0em;
  padding-left              : 0.35em;
}

.bccampus-toggle .bccampus-button.last-toggle-item {
  border-top-left-radius   : 0em;
  border-bottom-left-radius: 0em;
  padding-right            : 0.35em;
}

.bccampus-toggle .bccampus-button.toggle-item {
  border-radius: 0em;
}
