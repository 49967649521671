.bccampus-button {
  border-radius : var(--button-border-radius);
  border        : none;
  outline-offset: var(--outline-offset);
  cursor        : pointer;
  display       : inline-block;
  white-space   : nowrap;
  height        : 2.25em;
}

.bccampus-button>span {
  line-height: var(--button-text-size) !important;
}

/* Type: Default */
.bccampus-button.default {
  padding        : var(--button-text-padding);
  font-size      : var(--button-text-size);
  line-height    : var(--button-text-size);
  font-weight    : var(--button-text-weight);
  text-decoration: none !important;
  outline-offset : var(--outline-offset);
  border-style   : solid;
  border-width   : var(--button-border-width);
}

.bccampus-button.default.primary {
  background-color: var(--button-background-color);
  border-color    : var(--button-background-color);
  color           : var(--button-text-color);
}

.bccampus-button.default.primary:focus {
  outline: var(--button-outline-focus);
}

.bccampus-button.default.primary:hover {
  background-color: var(--button-background-color-hover);
  border-color    : var(--button-background-color-hover);
}

.bccampus-button.default.primary:active {
  background-color: var(--button-background-color-active);
  border-color    : var(--button-background-color-active);
}

.bccampus-button.default.secondary {
  background-color: transparent;
  border-color    : var(--button-background-color);
  color           : var(--button-background-color);
}

.bccampus-button.default.secondary:focus {
  outline: var(--button-outline-focus);
}

.bccampus-button.default.secondary:hover {
  background-color: var(--button-background-color-hover);
  border-color    : var(--button-background-color-hover);
  color           : var(--button-text-color);
}

.bccampus-button.default.secondary:active {
  background-color: var(--button-background-color-active);
  border-color    : var(--button-background-color-active);
}

/* In-Dark */
.background-dark .bccampus-button.default.primary,
.background-dark-alt .bccampus-button.default.primary {
  background-color: var(--button-background-color-in-dark);
  border-color    : var(--button-background-color-in-dark);
  color           : var(--button-text-color-in-dark) !important;
}

.background-dark .bccampus-button.default.primary:focus,
.background-dark-alt .bccampus-button.default.primary:focus {
  outline: var(--button-outline-focus-in-dark);
}

.background-dark .bccampus-button.default.primary:hover,
.background-dark-alt .bccampus-button.default.primary:hover {
  background-color: var(--button-background-color-hover-in-dark);
  border-color    : var(--button-background-color-hover-in-dark);
  color           : var(--button-text-color-hover-in-dark) !important;
}

.background-dark .bccampus-button.default.primary:active,
.background-dark-alt .bccampus-button.default.primary:active {
  background-color: var(--button-background-color-active-in-dark);
  border-color    : var(--button-background-color-active-in-dark);
  color           : var(--button-text-color-active-in-dark) !important;
}

.background-dark .bccampus-button.default.secondary,
.background-dark-alt .bccampus-button.default.secondary {
  border-color: var(--button-background-color-in-dark);
  color       : var(--button-background-color-in-dark) !important;
}

.background-dark .bccampus-button.default.secondary:focus,
.background-dark-alt .bccampus-button.default.secondary:focus {
  outline: var(--button-outline-focus-in-dark);
}

.background-dark .bccampus-button.default.secondary:hover,
.background-dark-alt .bccampus-button.default.secondary:hover {
  background-color: var(--button-background-color-hover-in-dark);
  border-color    : var(--button-background-color-hover-in-dark);
  color           : var(--button-text-color-hover-in-dark) !important;
}

.background-dark .bccampus-button.default.secondary:active,
.background-dark-alt .bccampus-button.default.secondary:active {
  background-color: var(--button-background-color-active-in-dark);
  border-color    : var(--button-background-color-active-in-dark);
  color           : var(--button-text-color-active-in-dark) !important;
}

/* Type: text */

.bccampus-button.text {
  font-size              : var(--link-text-size);
  color                  : var(--link-text-color) !important;
  text-decoration        : var(--link-text-decoration) !important;
  text-underline-position: under;
  background             : none;
  padding                : 0rem;
  font-weight            : var(--link-text-weight);
  border-radius          : var(--link-border-radius);
}

.bccampus-button.text.inline {
  display   : inline;
  text-align: left;
}

.bccampus-button.text:focus {
  color  : var(--link-text-color-focus) !important;
  outline: var(--link-outline-focus);
}

.bccampus-button.text:hover {
  color: var(--link-text-color-hover) !important;
}

.bccampus-button.text:active {
  color: var(--link-text-color-active) !important;
}

/* In-Dark */
.background-dark .bccampus-button.text,
.background-dark-alt .bccampus-button.text {
  color: var(--link-text-color-in-dark) !important;
}

.background-dark .bccampus-button.text:focus,
.background-dark-alt .bccampus-button.text:focus {
  color  : var(--link-text-color-focus-in-dark) !important;
  outline: var(--link-outline-focus-in-dark);
}

.background-dark .bccampus-button.text:hover,
.background-dark-alt .bccampus-button.text:hover {
  color: var(--link-text-color-hover-in-dark) !important;
}

.background-dark .bccampus-button.text:active,
.background-dark-alt .bccampus-button.text:active {
  color: var(--link-text-color-active-in-dark) !important;
}

.background-dark.hero-alt .bccampus-button.text {
  color: var(--button-text-color) !important;
}

/* Type: Icon */
.bccampus-button.icon {
  background-color: var(--button-background-color);
  color           : var(--button-text-color);
  font-size       : var(--button-text-size);
  line-height     : var(--button-text-size) !important;
  font-weight     : var(--button-text-weight);
  text-decoration : none !important;
  text-align      : center;
  border-style    : solid;
  border-width    : var(--button-border-width);
}

button.bccampus-button.icon {
  width  : 2.25em;
  height : 2.25em;
  padding: 0em;
}

span.bccampus-button.icon {
  padding: 0.5em 0.15em;
  width  : 2em;
  height : 2em;
}

.bccampus-button.icon.primary:focus {
  outline: var(--button-outline-focus);
}

.bccampus-button.icon.primary:hover {
  background-color: var(--button-background-color-hover);
}

.bccampus-button.icon.primary:active {
  background-color: var(--button-background-color-active);
}

.bccampus-button.icon.secondary {
  background-color: transparent;
  border-color    : var(--button-background-color);
  color           : var(--button-background-color);
}

.bccampus-button.icon.secondary:focus {
  outline: var(--button-outline-focus);
}

.bccampus-button.icon.secondary:hover {
  background-color: var(--button-background-color-hover);
  border-color    : var(--button-text-color);
  color           : var(--button-text-color);
}

.bccampus-button.icon.secondary:active {
  background-color: var(--button-background-color-active);
  border-color    : var(--button-background-color-active);
  color           : var(--button-text-color);
}

/* In-Dark */
.background-dark .bccampus-button.icon,
.background-dark-alt .bccampus-button.icon {
  background-color: var(--button-background-color-in-dark);
  color           : var(--button-text-color-in-dark) !important;
}

.background-dark .bccampus-button.icon:focus,
.background-dark-alt .bccampus-button.icon:focus {
  outline: var(--button-outline-focus-in-dark);
}

.background-dark .bccampus-button.icon:hover,
.background-dark-alt .bccampus-button.icon:hover {
  background-color: var(--button-background-color-hover-in-dark);
  color           : var(--button-text-color-hover-in-dark) !important;
}

.background-dark .bccampus-button.icon:active,
.background-dark-alt .bccampus-button.icon:active {
  background-color: var(--button-background-color-active-in-dark);
  color           : var(--button-text-color-active-in-dark) !important;
}

/* Type: Card */
.bccampus-button.card {
  border-radius : var(--card-border-radius);
  outline-offset: var(--outline-offset);
  box-shadow    : var(--card-shadow);
}

.bccampus-button.card:focus {
  outline: var(--card-outline-focus);
}

.bccampus-button.card:hover {
  box-shadow: var(--card-shadow-hover);
}

.bccampus-button.card:active {
  box-shadow: var(--card-shadow-active);
}

/* In-Dark */
.background-dark .bccampus-button.card,
.background-dark-alt .bccampus-button.card {
  box-shadow: var(--card-shadow-in-dark);
}

.background-dark .bccampus-button.card:focus,
.background-dark-alt .bccampus-button.card:focus {
  outline: var(--card-outline-focus-in-dark);
}

.background-dark .bccampus-button.card:hover,
.background-dark-alt .bccampus-button.card:hover {
  box-shadow: var(--card-shadow-hover-in-dark);
}

.background-dark .bccampus-button.card:active,
.background-dark-alt .bccampus-button.card:active {
  box-shadow: var(--card-shadow-active-in-dark);
}

/* Misc */
.bccampus-button .bccampus-button-icon.align-right {
  margin-left: 0.5em;
}

.bccampus-button .bccampus-button-icon.align-left {
  margin-right: 0.5em;
}

.bccampus-button.icon .bccampus-button-icon {
  margin: 0em;
  float : none;
}
