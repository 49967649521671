.bccampus-cms-content a,
a.bccampus-link {
  border: none;
}

.bccampus-cms-content a>span,
a.bccampus-link>span {
  height: 100%;
}

a.bccampus-link.header {
  font-weight    : 600;
  font-size      : 1.35em;
  line-height    : 1.35em;
  text-decoration: none !important;
}

.bccampus-cms-content a,
a.bccampus-link.default {
  font-size              : var(--link-text-size);
  color                  : var(--link-text-color) !important;
  text-decoration        : var(--link-text-decoration) !important;
  text-underline-position: under;
  font-weight            : var(--link-text-weight);
  border-radius          : var(--link-border-radius);
  outline-offset         : var(--outline-offset);
}

.bccampus-cms-content a:focus,
a.bccampus-link.default:focus {
  color  : var(--link-text-color-focus) !important;
  outline: var(--link-outline-focus);
}

.bccampus-cms-content a:hover,
a.bccampus-link.default:hover {
  color: var(--link-text-color-hover) !important;
}

.bccampus-cms-content a:active,
a.bccampus-link.default:active {
  color: var(--link-text-color-active) !important;
}

/* In-Dark */
.background-dark .bccampus-cms-content a,
.background-dark a.bccampus-link.default,
.background-dark-alt a.bccampus-link.default {
  color: var(--link-text-color-in-dark) !important;
}

.background-dark .bccampus-cms-content a:focus,
.background-dark a.bccampus-link.default:focus,
.background-dark-alt a.bccampus-link.default:focus {
  color  : var(--link-text-color-focus-in-dark) !important;
  outline: var(--link-outline-focus-in-dark);
}

.background-dark .bccampus-cms-content a:hover,
.background-dark a.bccampus-link.default:hover,
.background-dark-alt a.bccampus-link.default:hover {
  color: var(--link-text-color-hover-in-dark) !important;
}

.background-dark .bccampus-cms-content a:active,
.background-dark a.bccampus-link.default:active,
.background-dark-alt a.bccampus-link.default:active {
  color: var(--link-text-color-active-in-dark) !important;
}

/* Type: Button */
a.bccampus-link.button {
  display        : inline-block;
  border-radius  : var(--button-border-radius);
  padding        : var(--button-text-padding);
  font-size      : var(--button-text-size);
  line-height    : var(--button-text-size);
  font-weight    : var(--button-text-weight);
  text-decoration: none !important;
  outline-offset : var(--outline-offset);
  border-style   : solid;
  border-width   : var(--button-border-width);
}

a.bccampus-link.button>span {
  display: inline-block;
}

a.bccampus-link.button.primary {
  background-color: var(--button-background-color);
  border-color    : var(--button-background-color);
  color           : var(--button-text-color);
}

a.bccampus-link.button.primary:focus {
  outline: var(--button-outline-focus);
}

a.bccampus-link.button.primary:hover {
  background-color: var(--button-background-color-hover);
  border-color    : var(--button-background-color-hover);
}

a.bccampus-link.button.primary:active {
  background-color: var(--button-background-color-active);
  border-color    : var(--button-background-color-active);
}


a.bccampus-link.button.secondary {
  background-color: transparent;
  border-color    : var(--button-background-color);
  color           : var(--button-background-color);
}

a.bccampus-link.button.secondary:focus {
  outline: var(--button-outline-focus);
}

a.bccampus-link.button.secondary:hover {
  background-color: var(--button-background-color-hover);
  border-color    : var(--button-background-color-hover);
  color           : var(--button-text-color);
}

a.bccampus-link.button.secondary:active {
  background-color: var(--button-background-color-active);
  border-color    : var(--button-background-color-hover);
}

/* In-Dark */
.background-dark a.bccampus-link.button.primary,
.background-dark-alt a.bccampus-link.button.primary {
  background-color: var(--button-background-color-in-dark);
  border-color    : var(--button-background-color-in-dark);
  color           : var(--button-text-color-in-dark) !important;
}

.background-dark a.bccampus-link.button.primary:focus,
.background-dark-alt a.bccampus-link.button.primary:focus {
  outline: var(--button-outline-focus-in-dark);
}

.background-dark a.bccampus-link.button.primary:hover,
.background-dark-alt a.bccampus-link.button.primary:hover {
  background-color: var(--button-background-color-hover-in-dark);
  border-color    : var(--button-background-color-hover-in-dark);
  color           : var(--button-text-color-hover-in-dark) !important;
}

.background-dark a.bccampus-link.button.primary:active,
.background-dark-alt a.bccampus-link.button.primary:active {
  background-color: var(--button-background-color-active-in-dark);
  border-color    : var(--button-background-color-active-in-dark);
  color           : var(--button-text-color-active-in-dark) !important;
}

.background-dark a.bccampus-link.button.secondary,
.background-dark-alt a.bccampus-link.button.secondary {
  border-color: var(--button-background-color-in-dark);
  color       : var(--button-background-color-in-dark) !important;
}

.background-dark a.bccampus-link.button.secondary:focus,
.background-dark-alt a.bccampus-link.button.secondary:focus {
  outline: var(--button-outline-focus-in-dark);
}

.background-dark a.bccampus-link.button.secondary:hover,
.background-dark-alt a.bccampus-link.button.secondary:hover {
  background-color: var(--button-background-color-hover-in-dark);
  border-color    : var(--button-background-color-hover-in-dark);
  color           : var(--button-text-color-hover-in-dark) !important;
}

.background-dark a.bccampus-link.button.secondary:active,
.background-dark-alt a.bccampus-link.button.secondary:active {
  background-color: var(--button-background-color-active-in-dark);
  border-color    : var(--button-background-color-active-in-dark);
  color           : var(--button-text-color-active-in-dark) !important;
}

/* Type: Icon */
a.bccampus-link.icon {
  display         : inline-block;
  border-radius   : var(--button-border-radius);
  background-color: var(--button-background-color);
  width           : 2em;
  height          : 2em;
  padding-top     : 0.35em;
  color           : var(--button-text-color);
  font-size       : var(--button-text-size) !important;
  line-height     : var(--button-text-size) !important;
  font-weight     : var(--button-text-weight);
  text-decoration : none !important;
  outline-offset  : var(--outline-offset);
  text-align      : center;
}

a.bccampus-link.icon:focus {
  outline: var(--button-outline-focus);
}

a.bccampus-link.icon:hover {
  background-color: var(--button-background-color-hover);
}

a.bccampus-link.icon:active {
  background-color: var(--button-background-color-active);
}

a.bccampus-link.icon.secondary {
  background-color: transparent !important;
  color           : var(--button-background-color) !important;
}

a.bccampus-link.icon.secondary:focus {
  outline: var(--button-outline-focus);
}

a.bccampus-link.icon.secondary:hover {
  background-color: var(--button-background-color-hover);
  border-color    : var(--button-background-color-hover);
}

a.bccampus-link.icon.secondary:active {
  background-color: var(--button-background-color-active);
  border-color    : var(--button-background-color-hover);
}

/* In-Dark */
.background-dark a.bccampus-link.icon,
.background-dark-alt a.bccampus-link.icon {
  background-color: var(--button-background-color-in-dark);
  color           : var(--button-text-color-in-dark) !important;
}

.background-dark a.bccampus-link.icon:focus,
.background-dark-alt a.bccampus-link.icon:focus {
  outline: var(--button-outline-focus-in-dark);
}

.background-dark a.bccampus-link.icon:hover,
.background-dark-alt a.bccampus-link.icon:hover {
  background-color: var(--button-background-color-hover-in-dark);
  color           : var(--button-text-color-hover-in-dark) !important;
}

.background-dark a.bccampus-link.icon:active,
.background-dark-alt a.bccampus-link.icon:active {
  color: var(--button-background-color-active-in-dark);
}

.background-dark a.bccampus-link.icon.secondary,
.background-dark-alt a.bccampus-link.icon.secondary {
  color: var(--button-background-color-in-dark) !important;
}

.background-dark a.bccampus-link.icon.secondary:focus,
.background-dark-alt a.bccampus-link.icon.secondary:focus {
  outline: var(--button-outline-focus-in-dark) !important;
}

.background-dark a.bccampus-link.icon.secondary:hover,
.background-dark-alt a.bccampus-link.icon.secondary:hover {
  color: var(--button-background-color-hover-in-dark) !important;
}

.background-dark a.bccampus-link.icon.secondary:active,
.background-dark-alt a.bccampus-link.icon.secondary:active {
  background-color: var(--button-background-color-active-in-dark) !important;
  color           : var(--button-text-color-active-in-dark) !important;
}


/* Type: Card */
a.bccampus-link.card {
  display       : inline-block;
  border-radius : var(--card-border-radius);
  outline-offset: var(--outline-offset);
  box-shadow    : var(--card-shadow);
}

a.bccampus-link.card:focus {
  outline: var(--card-outline-focus);
}

a.bccampus-link.card:hover {
  box-shadow: var(--card-shadow-hover);
}

a.bccampus-link.card:active {
  box-shadow: var(--card-shadow-active);
}

/* In-Dark */
.background-dark a.bccampus-link.card,
.background-dark-alt a.bccampus-link.card {
  box-shadow: var(--card-shadow-in-dark);
}

.background-dark a.bccampus-link.card:focus,
.background-dark-alt a.bccampus-link.card:focus {
  outline: var(--card-outline-focus-in-dark);
}

.background-dark a.bccampus-link.card:hover,
.background-dark-alt a.bccampus-link.card:hover {
  box-shadow: var(--card-shadow-hover-in-dark);
}

.background-dark a.bccampus-link.card:active,
.background-dark-alt a.bccampus-link.card:active {
  box-shadow: var(--card-shadow-active-in-dark);
}

/* Misc */
a.bccampus-link.header {
  font-weight    : 600;
  font-size      : 1.25em;
  line-height    : 1.25em;
  text-decoration: none !important;
}

a .bccampus-link-icon.align-left {
  margin-right: 0.5em;
}

a .bccampus-link-icon.align-right {
  margin-left: 0.5em;
}

a.icon .bccampus-link-icon {
  margin: 0em;
  float : none;
}
