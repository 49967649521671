.bccampus-material-info-card {
    padding: 0em;
    width  : 100%;
    height : 100%;
    background-color: var(--background-color);
}

.bccampus-material-info-card:hover {
    box-shadow: 0px 0px 8px 2px #ac0660 !important;
}

.bccampus-material-info-card-cover {
    object-fit   : cover;
    object-position: center top;
    width        : 100%;
    height       : 14em;
    border-radius: 8px 8px 0px 0px !important;
}

.bccampus-material-info-card-body {
    height : 11em;
    padding: 1em 1.5em;
}

.bccampus-material-info-card-meta {
    width        : 100%;
    min-height   : 4.25em;
    padding      : 0em 1.5em 1.5em 1.5em;
    border-radius: 0px 0px 8px 8px !important;
}

.bccampus-material-info-card-meta * {
    font-size: 13px !important;
}

.bccampus-material-info-card-tag {
    padding         : 0.5em 1.5em;
    background-color: #ac0660;
    color           : #ffffff;
}
