@import '~antd/dist/antd.less';

:root {
  --base-font-size        : @font-size-base;
  --title-font-size       : 2.5em;
  --primary-color         : #0077aa;
  --primary-color-light   : #a6def4;
  --primary-color-dark    : #0b2651;
  --primary-color-dark-alt: #081e40;
  --secondary-color       : #754686;
  --secondary-color-light : #cd98df;
  --secondary-color-dark  : #3a1248;

  --background-color         : #ffffff;
  --background-color-alt     : #f7f5f7;
  --background-color-dark    : #0b2651;
  --background-color-dark-alt: #081e40;
  --background-color-light   : #e8eced;

  --background-color-rgb         : 255, 255, 255;
  --background-color-alt-rgb     : 247, 245, 247;
  --background-color-dark-rgb    : 11, 38, 81;
  --background-color-dark-alt-rgb: 8, 30, 64;

  --text-color-light: #fdfdfd;
  --text-color-dark : #343434;

  --border-radius: 8px;
  --border-color : @border-color-base;
  --border-width : @border-width-base;
  --border-style : @border-width-base @border-style-base @border-color-base;

  --outline-offset: 2px;

  // Link
  --link-text-size      : 1em;
  --link-text-weight    : 400;
  --link-text-decoration: underline;
  --link-border-radius  : 2px;

  --link-text-color       : var(--primary-color);
  --link-text-color-hover : var(--primary-color-dark);
  --link-text-color-active: var(--secondary-color-dark);
  --link-text-color-focus : var(--primary-color);
  --link-outline-focus    : 2px solid var(--secondary-color-light);

  --link-text-color-in-dark       : var(--primary-color-light);
  --link-text-color-hover-in-dark : var(--primary-color);
  --link-text-color-active-in-dark: var(--primary-color-dark);
  --link-text-color-focus-in-dark : var(--primary-color-light);
  --link-outline-focus-in-dark    : 2px solid var(--secondary-color-light);

  // Button
  --button-text-size    : calc(var(--base-font-size) + 2px);
  --button-border-radius: calc(var(--button-text-size) * 2);
  --button-border-width : 2px;
  --button-text-weight  : 600;
  --button-text-padding : 0.5em 1em;

  --button-text-color             : var(--text-color-light);
  --button-background-color       : var(--primary-color);
  --button-outline-focus          : 3px solid var(--secondary-color-light);
  --button-background-color-hover : var(--primary-color-dark);
  --button-background-color-active: var(--secondary-color-dark);

  --button-text-color-in-dark             : var(--text-color-dark);
  --button-background-color-in-dark       : var(--background-color);
  --button-outline-focus-in-dark          : 3px solid var(--secondary-color-light);
  --button-background-color-hover-in-dark : var(--primary-color-light);
  --button-text-color-hover-in-dark       : var(--primary-color-dark-alt);
  --button-background-color-active-in-dark: var(--primary-color-dark);
  --button-text-color-active-in-dark      : var(--primary-color-light);

  // Card
  --card-border-radius: var(--border-radius);
  --card-shadow       : 0px 0px 8px 2px #dddddd;
  --card-shadow-hover : 0px 0px 8px 2px var(--primary-color);
  --card-shadow-active: 0px 0px 8px 2px var(--secondary-color-dark);
  --card-outline-focus: 3px solid var(--secondary-color-light);

  --card-shadow-in-dark       : 0px 0px 8px 2px #112747;
  --card-shadow-hover-in-dark : 0px 0px 8px 2px var(--primary-color);
  --card-shadow-active-in-dark: 0px 0px 8px 2px var(--secondary-color-dark);
  --card-outline-focus-in-dark: 3px solid var(--secondary-color-light);

  // Misc
  --success-color: @success-color;
  --error-color  : @error-color;

  --app-bar-height                : 65px;
  --content-menu-height           : 65px;
  --app-sidebar-width             : 100px;
  --content-sidebar-width         : 75px;
  --padding                       : 1em;
  --page-section-padding-v        : 2em;
  --page-section-padding-h        : 5em;
  --page-section-left-content-dir : row-reverse;
  --page-section-image-width      : calc(100% - 2em);
  --page-section-image-position   : absolute;
  --page-section-right-content-dir: row;
  --page-section-container-width  : 100%;
  --page-section-container-margin : 0em;

  --home-page-hero-image-display    : block;
  --home-page-section-content-pading: 3em 0em;

  font-size: 87.5%;
}


.grecaptcha-badge {
  visibility: hidden;
}

body,
.background-default {
  background-color: var(--background-color);
}

.background-alt {
  background-color: var(--background-color-alt);
}

.background-dark {
  background-color: var(--background-color-dark);
}

.background-dark-alt {
  background-color: var(--background-color-dark-alt);
}

.background-transparent {
  background-color: transparent;
}

.background-dark.hero {
  background: linear-gradient(180deg, var(--background-color-dark), 55%, var(--secondary-color-dark));
}

.background-dark.hero-alt {
  background: linear-gradient(180deg, var(--background-color-dark), 55%, #28837e);
}

body,
.background-default,
.background-alt,
.background-transparent {
  color    : var(--text-color-dark) !important;
  font-size: var(--base-font-size);

  & ::selection {
    background-color: var(--background-color-dark) !important;
    color           : var(--text-color-light) !important;
  }
}

.background-dark,
.background-dark-alt {
  color: var(--text-color-light) !important;

  & ::selection {
    background-color: var(--text-color-light) !important;
    color           : var(--background-color-dark) !important;
  }
}

.background-dark .bccampus-logo,
.background-dark-alt .bccampus-logo {
  transition: fill 0.3s, stroke 0.3s;
  fill      : var(--text-color-light) !important;
  stroke    : var(--text-color-light) !important;
}

.background-dark .bccampus-logo:hover,
.background-dark-alt .bccampus-logo:hover {
  fill  : var(--primary-color-light) !important;
  stroke: var(--primary-color-light) !important;
}

.page-header .bccampus-img.in-large-page {
  display: block;
}

.page-header .bccampus-img.in-small-page {
  display: none;
}

.page-header .bccampus-item-group {
  justify-content: center;
}

.bccampus-search-filter-tag.focused,
.bccampus-search-filter-tag:focus {
  outline       : var(--button-outline-focus) !important;
  outline-offset: var(--outline-offset) !important;
}

.ant-input {
  padding: 0.5rem !important;
}

.ant-select-selector {
  height : 2.715em !important;
  padding: 0.25em 0.5em !important;
}

.ant-select-selection-search-input {
  height: 2.715em !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  left: 0.5em !important;
}

.ant-radio-group,
.ant-checkbox-group {
  flex-direction: column;
  display       : inline-flex;
}

.ant-select-tree-checkbox-inner,
.ant-checkbox-inner {
  border-radius: 4px;
}

.ant-tree-select .ant-select-selector {
  padding: 0em 0.5em !important;
}

.bccampus-cms-content a[href^="http"]::after,
a.external::after {
  content            : "";
  width              : 0.875rem;
  height             : 0.875rem;
  margin-left        : 0.25rem;
  margin-right       : 0.125rem;
  background-image   : url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%20color%3D%22%230077aa%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M352%200c-12.9%200-24.6%207.8-29.6%2019.8s-2.2%2025.7%206.9%2034.9L370.7%2096%20201.4%20265.4c-12.5%2012.5-12.5%2032.8%200%2045.3s32.8%2012.5%2045.3%200L416%20141.3l41.4%2041.4c9.2%209.2%2022.9%2011.9%2034.9%206.9s19.8-16.6%2019.8-29.6l0-128c0-17.7-14.3-32-32-32L352%200zM80%2032C35.8%2032%200%2067.8%200%20112L0%20432c0%2044.2%2035.8%2080%2080%2080l320%200c44.2%200%2080-35.8%2080-80l0-112c0-17.7-14.3-32-32-32s-32%2014.3-32%2032l0%20112c0%208.8-7.2%2016-16%2016L80%20448c-8.8%200-16-7.2-16-16l0-320c0-8.8%207.2-16%2016-16l112%200c17.7%200%2032-14.3%2032-32s-14.3-32-32-32L80%2032z%22%2F%3E%3C%2Fsvg%3E");
  background-position: bottom center;
  background-repeat  : no-repeat;
  background-size    : contain;
  display            : inline-block;
}

.background-dark .bccampus-cms-content a[href^="http"]::after,
.background-dark-alt .bccampus-cms-content a[href^="http"]::after {
  content            : "";
  width              : 0.875rem;
  height             : 0.875rem;
  margin-left        : 0.25rem;
  margin-right       : 0.125rem;
  background-image   : url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%20color%3D%22%23a6def4%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M352%200c-12.9%200-24.6%207.8-29.6%2019.8s-2.2%2025.7%206.9%2034.9L370.7%2096%20201.4%20265.4c-12.5%2012.5-12.5%2032.8%200%2045.3s32.8%2012.5%2045.3%200L416%20141.3l41.4%2041.4c9.2%209.2%2022.9%2011.9%2034.9%206.9s19.8-16.6%2019.8-29.6l0-128c0-17.7-14.3-32-32-32L352%200zM80%2032C35.8%2032%200%2067.8%200%20112L0%20432c0%2044.2%2035.8%2080%2080%2080l320%200c44.2%200%2080-35.8%2080-80l0-112c0-17.7-14.3-32-32-32s-32%2014.3-32%2032l0%20112c0%208.8-7.2%2016-16%2016L80%20448c-8.8%200-16-7.2-16-16l0-320c0-8.8%207.2-16%2016-16l112%200c17.7%200%2032-14.3%2032-32s-14.3-32-32-32L80%2032z%22%2F%3E%3C%2Fsvg%3E");
  background-position: bottom center;
  background-repeat  : no-repeat;
  background-size    : contain;
  display            : inline-block;
}

@media (max-width: @screen-sm-min) {
  :root {
    --title-font-size: 2em;
  }

  .page-header .bccampus-img.in-large-page {
    display: none;
  }

  .page-header .bccampus-img.in-small-page {
    display   : block;
    width     : 100%;
    max-height: 24em;
  }

  .page-header .bccampus-item-group {
    justify-content: space-between;
    width          : 100%;
  }

  .page-header .bccampus-item-group>* {
    flex      : auto;
    width     : 100%;
    text-align: center;
  }
}

@media (max-width: @screen-md-min) {
  :root {
    --page-section-padding-v: 2em;
    --page-section-padding-h: 1.25em;

    --page-section-image-width   : 65%;
    --page-section-image-position: relative;

    --page-section-left-content-dir : column;
    --page-section-right-content-dir: column;

    --home-page-hero-image-display    : none;
    --home-page-section-content-pading: 0em 0em;
  }
}

@media (min-width: @screen-md-min) and (max-width: @screen-lg-max) {
  :root {
    --home-page-hero-image-display: none;
    --page-section-padding-v      : 2em;
    --page-section-padding-h      : 2em;
  }
}

@media (min-width: @screen-xxl-min) {
  :root {
    --page-section-padding-v: 2em;
    --page-section-padding-h: 5em;

    --page-section-container-width : 1440px;
    --page-section-container-margin: auto;
  }
}

@media (max-width: @screen-lg-max) {
  .bccampus-site-header .large-header {
    display: none;
  }

  .bccampus-site-footer {
    text-align: center;
  }
}

@media (min-width: @screen-xl-min) {
  .bccampus-site-header .small-header {
    display: none;
  }

}

@font-size-base: 16px;@primary-color: #1d435d;@border-radius-base: 8px;@link-color: #017eb5;@input-placeholder-color: #707070;@border-color-base: #707070;@error-color: #EB0004;