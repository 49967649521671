/* .bccampus-navigation.horizontal {} */
.bccampus-navigation.vertical {
    text-align: center;
}

.bccampus-navigation-item {
    color      : var(--text-color-light);
    font-weight: 600;
    border     : var(--link-text-border);
}

.bccampus-navigation-item:focus {
    outline       : 3px solid var(--secondary-color-light) !important;
    outline-offset: var(--outline-offset);
    border-radius : 2px !important;
}

.bccampus-navigation-item:hover {
    color : var(--text-color-light);
    border: var(--link-text-border);
}

.bccampus-navigation.horizontal>.bccampus-navigation-item {
    padding: calc(var(--padding)/2) 0em;
    margin : 0em 1em;
}

.bccampus-navigation.vertical>.bccampus-navigation-item {
    display    : block;
    font-Size  : 0.75em;
    line-height: 2em;
    margin     : 1em 0em;
    padding    : var(--padding)
}

.bccampus-navigation.vertical>.bccampus-navigation-item>.anticon {
    display  : block;
    font-size: 2em;
}

.bccampus-navigation-item.active,
.bccampus-navigation-item.active:focus {
    color : var(--text-color-light);
    border: var(--link-text-border);
}

.bccampus-navigation.horizontal>.bccampus-navigation-item.active {
    border-bottom: 4px solid var(--text-color-light);
}

.bccampus-navigation.vertical>.bccampus-navigation-item.active {
    border-right: 4px solid var(--text-color-light);
}