.bccampus-img.bordered {
  border-style: solid;
  border-width: 4px;
  border-color: var(--background-color-light);
}

.bccampus-img.rounded {
  border-radius: var(--border-radius) !important;
}

.background-dark .bccampus-img.bordered,
.background-dark-alt .bccampus-img.bordered {
  border-color: var(--background-color-alt);
}

.bccampus-img svg {
  fill: currentColor;
}

.bccampus-img.icon {
  display    : inline-block;
  line-height: 1em;
}

img.bccampus-img.icon,
.bccampus-img.icon svg {
  height: 1rem;
}

img.bccampus-img.size-2x,
.bccampus-img.size-2x svg {
  height: 2rem;
}

img.bccampus-img.size-3x,
.bccampus-img.size-3x svg {
  height: 3rem;
}

img.bccampus-img.size-4x,
.bccampus-img.size-4x svg {
  height: 4rem;
}

img.bccampus-img.size-5x,
.bccampus-img.size-5x svg {
  height: 5rem;
}

img.bccampus-img.size-6x,
.bccampus-img.size-6x svg {
  height: 6rem;
}

img.bccampus-img.size-7x,
.bccampus-img.size-7x svg {
  height: 7rem;
}

img.bccampus-img.size-8x,
.bccampus-img.size-8x svg {
  height: 8rem;
}
