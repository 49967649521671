.bccampus-search-filter-box {
  padding    : 1em !important;
  line-height: 1em !important;
  height     : 6.5em;
  width      : 100%;
  overflow   : hidden;
  position   : relative;
  text-align : left !important;
  display    : inline-flex;
  align-items: flex-start;
}

.bccampus-search-filter-box:hover {
  box-shadow: 0px 0px 8px 2px !important;
}

.bccampus-search-filter-box-label {
  position   : relative;
  font-size  : 1em !important;
  line-height: 1em !important;
  font-weight: 600 !important;
  width      : 100%;
  height     : 100%;
}

.bccampus-search-filter-box-extra {
  font-size: 1em;
  position : absolute;
  bottom   : 0.5em;
  left     : 1em;
}

.bccampus-search-filter-box-icon {
  position   : absolute;
  line-height: 0px;
  padding    : 0em;
  margin     : 0em;
  bottom     : 0.1em;
  right      : 0.1em;
  transition : opacity 0.5s;
}

.bccampus-search-filter-box-icon svg,
.bccampus-search-filter-box-icon img {
  height: 2.5rem;
}

.bccampus-search-filter-box-icon img {
  object-fit: contain;
}

.bccampus-search-filter-box:hover .bccampus-search-filter-box-icon {
  opacity: 0.6 !important;
}
