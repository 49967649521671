/* Text */
.bccampus-cms-content,
.bccampus-typography-text {
  color       : var(--text-color-dark) !important;
  font-weight : normal !important;
  font-size   : 1em !important;
  line-height : 1.5em !important;
  font-stretch: normal;
  font-style  : normal;
  margin      : 0rem;
  padding     : 0rem;
}

.background-dark .bccampus-typography-text,
.background-dark-alt .bccampus-typography-text {
  color: var(--text-color-light) !important;
}

/* Label */

.bccampus-typography-label {
  color       : var(--text-color-dark) !important;
  font-weight : 600 !important;
  font-size   : 1.15em !important;
  line-height : 1.15em !important;
  font-stretch: normal;
  font-style  : normal;
  margin      : 0rem;
  padding     : 0rem;
}

.background-dark .bccampus-typography-label,
.background-dark-alt .bccampus-typography-label {
  color: var(--text-color-light) !important;
}

/* Subtitle */

.bccampus-typography-subtitle {
  color       : var(--text-color-dark) !important;
  font-weight : 600 !important;
  font-size   : calc(var(--title-font-size) * 0.6) !important;
  line-height : 1.15em !important;
  font-stretch: normal;
  font-style  : normal;
  margin      : 0rem !important;
  padding     : 0rem !important;
}

.background-dark .bccampus-typography-subtitle,
.background-dark-alt .bccampus-typography-subtitle {
  color: var(--text-color-light) !important;
}

/* Heading */
.bccampus-cms-content h1,
.bccampus-cms-content h2,
.bccampus-cms-content h3,
.bccampus-cms-content h4,
.bccampus-cms-content h5,
.bccampus-cms-content h6,
.bccampus-typography-heading {
  color       : var(--text-color-dark) !important;
  line-height : 1.25em !important;
  font-stretch: normal;
  font-style  : normal;
  margin      : 0rem;
  padding     : 0rem;
}

.background-dark .bccampus-cms-content h1,
.background-dark .bccampus-cms-content h2,
.background-dark .bccampus-cms-content h3,
.background-dark .bccampus-cms-content h4,
.background-dark .bccampus-cms-content h5,
.background-dark .bccampus-cms-content h6,
.background-dark .bccampus-typography-heading,
.background-dark-alt .bccampus-typography-heading {
  color: var(--text-color-light) !important;
}

.bccampus-cms-content h1,
.bccampus-typography-heading.level-1 {
  font-weight  : bold !important;
  font-size    : var(--title-font-size) !important;
  margin-bottom: 1rem;
}

.bccampus-cms-content h2,
.bccampus-typography-heading.level-2 {
  font-weight  : bold !important;
  font-size    : calc(var(--title-font-size) * 0.7) !important;
  margin-bottom: 1rem;
}

.bccampus-cms-content h2.feature,
.bccampus-typography-heading.level-2.feature {
  font-size    : 1em !important;
  margin-bottom: 1rem;
}

.bccampus-cms-content h3,
.bccampus-typography-heading.level-3 {
  font-weight  : normal !important;
  font-size    : calc(var(--title-font-size) * 0.6) !important;
  margin-bottom: 0.5rem;
}

.bccampus-cms-content h4,
.bccampus-typography-heading.level-4 {
  font-weight  : normal !important;
  font-size    : calc(var(--title-font-size) * 0.55) !important;
  margin-bottom: 0.5rem;
}

.bccampus-cms-content h5,
.bccampus-typography-heading.level-5 {
  font-weight  : normal !important;
  font-size    : calc(var(--title-font-size) * 0.45) !important;
  margin-bottom: 0.25rem;
}

.bccampus-cms-content h6,
.bccampus-typography-heading.level-6 {
  font-weight  : normal !important;
  font-size    : calc(var(--title-font-size) * 0.40) !important;
  margin-bottom: 0.25rem;
}

/* Paragraph */
.bccampus-cms-content p,
.bccampus-typography-paragraph {
  color        : var(--text-color-dark) !important;
  font-weight  : normal !important;
  font-size    : 1em !important;
  line-height  : 1.5em !important;
  font-stretch : normal;
  font-style   : normal;
  margin       : 0rem;
  padding      : 0rem;
  margin-bottom: 1rem;
}

.background-dark .bccampus-typography-paragraph,
.background-dark-alt .bccampus-typography-paragraph {
  color: var(--text-color-light) !important;
}


.bccampus-typography-paragraph.strong,
.bccampus-typography-text.strong {
  font-weight: 600 !important;
}

.bccampus-text-icon {
  margin-right: 0.5em;
}

/* Quote */
.bccampus-typography-quote {
  padding    : 0em;
  margin     : 0em;
  font-size  : 1em;
  line-height: 1.5em;
}

.bccampus-typography-quote::before {
  content    : "“";
  font-size  : 2em;
  line-height: 0em;
}

.bccampus-typography-quote .quotation-mark {
  font-size  : 2em;
  line-height: 0em;
}

.bccampus-cms-content blockquote {
  border-left : 0.5rem solid var(--background-color-light);
  padding-left: 1.5rem;
}

.bccampus-cms-content figure.media {
  border       : var(--border-width) solid #f0f0f0;
  border-radius: var(--border-radius);
  padding      : 1em;
}
